import Vue from 'vue';
import VClamp from "vue-clamp";

export default Vue.extend({
  name: 'Kitchens',
  components: {
    VClamp
  },
  props: {
    kitchens: {
      type: [Object, Array],
      default: () => {},
      required: true
    }
  },
  data: () => ({
    selectedKitchen: -1
  }),
  methods: {
    selectKitchen(id) {
      this.kitchens[id].selected = true;
      this.$emit("select", this.kitchens, id);
      // eslint-disable-next-line no-return-assign
      return (this.selectedKitchen === id) ? this.selectedKitchen = -1 : this.selectedKitchen = id;
    }
  }
});
